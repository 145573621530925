import { useAppSelector } from '@/lib/hooks';
import { Navigate, useLocation } from 'react-router-dom';

const useAuth = () => {
    const { authenticatedUser } = useAppSelector((state) => state.user);
    // const authorized = authenticatedUser.accessToken ? true : false;

    // const isUserAuthenticated = authorized;
    // return isUserAuthenticated;

    const authorized = authenticatedUser && authenticatedUser.accessToken;
    return authorized;
};
const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
    const location = useLocation();
    const isAuth = useAuth();

    return isAuth ? children : <Navigate to="/auth/login" state={{ from: location }} />;
};

export default ProtectedRoute;
