import { useAppSelector } from '@/lib/hooks';
import { Navigate, useLocation } from 'react-router-dom';

const useAuth = () => {
    const { authenticatedUser } = useAppSelector((state) => state.user);
    return authenticatedUser && authenticatedUser.accessToken;
};

const NotRoleChecker = ({ children }: { children: JSX.Element }) => {
    const location = useLocation();
    const isAuth = useAuth();
    return !isAuth ? <Navigate to="/login" state={{ from: location }} /> : children;
};

export default NotRoleChecker;
