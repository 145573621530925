import React, { ComponentType } from 'react';

interface IRoutes {
    route: string;
    component: ComponentType<any>;
    access?: string[] | undefined;
}

const Buildings = React.lazy(() => import('@/pages/Buildings/buildings.tsx'));
const Meters = React.lazy(() => import('@/pages/Meters/MeterScreen'));
const HouseManagementBuildings = React.lazy(
    () => import('@/pages/HouseManagement/HouseManagementBuildings.tsx'),
);
const HouseManagementScreen = React.lazy(
    () => import('@/pages/HouseManagement/HouseManagementScreen'),
);

const OwnersScreen = React.lazy(() => import('@/pages/CommunityOwners/OwnersScreen'));
const CMetersScreen = React.lazy(() => import('@/pages/ContractorMeters/CMetersScreen'));
const Controllers = React.lazy(() => import('@/pages/Contractors/ContractorsScreen'));
const BuildingMeters = React.lazy(
    () => import('@/pages/Buildings/pages/building-meters/building-meters.tsx'),
);
const Readings = React.lazy(() => import('@/pages/Readings/ReadingsScreen'));
const UsersScreen = React.lazy(() => import('@/pages/Users/users.tsx'));

const Error = React.lazy(() => import('@/pages/Error'));

const AuthRequiredRoute: IRoutes[] = [
    {
        route: '/buildings',
        component: Buildings,
        access: ['NONE', 'USER', 'ADMIN', 'SUPER_ADMIN'],
    },
    {
        route: '/meters',
        component: Meters,
        access: ['NONE', 'USER', 'ADMIN', 'SUPER_ADMIN'],
    },
    {
        route: '/building',
        component: BuildingMeters,
        access: ['NONE', 'USER', 'ADMIN', 'SUPER_ADMIN'],
    },
    {
        route: '/buildings/:buildingId',
        component: BuildingMeters,
        access: ['NONE', 'USER', 'ADMIN', 'SUPER_ADMIN'],
    },
    {
        route: '/meters/:meterId/readings/',
        component: Readings,
        access: ['NONE', 'USER', 'ADMIN', 'SUPER_ADMIN'],
    },
    {
        route: '/contractors',
        component: Controllers,
        access: ['NONE', 'USER', 'ADMIN', 'SUPER_ADMIN'],
    },
    {
        route: '/contractors/:contractorId',
        component: CMetersScreen,
        access: ['NONE', 'USER', 'ADMIN', 'SUPER_ADMIN'],
    },
    {
        route: '/users',
        component: UsersScreen,
        access: ['ADMIN', 'SUPER_ADMIN'],
    },
    {
        route: '/house-managers',
        component: HouseManagementScreen,
        access: ['SUPER_ADMIN'],
    },
    {
        route: '/house-managers/:houseManagementId',
        component: HouseManagementBuildings,
        access: ['NONE', 'USER', 'ADMIN', 'SUPER_ADMIN'],
    },
    {
        route: '/community-owners',
        component: OwnersScreen,
        access: ['NONE', 'USER', 'ADMIN', 'SUPER_ADMIN'],
    },
    {
        route: '*',
        component: Error,
        access: ['NONE', 'USER', 'ADMIN', 'SUPER_ADMIN'],
    },
];

export { AuthRequiredRoute };
