import Auth from '@/pages/auth/auth.tsx';
import { RouteObject } from 'react-router';
import RedirectChecker from '@/route/redirect-checker.tsx';
import { Login } from '@/pages/auth/pages/login/login.tsx';
import { PasswordReset } from '@/pages/auth/pages/password-reset/password-reset.tsx';
import { Navigate, useLocation } from 'react-router-dom';
import { PasswordChange } from '@/pages/auth/pages/password-change/password-change.tsx';

export enum AuthRoutes {
    AUTH = 'auth',
    LOGIN = 'login',
    PASSWORD_RESET = 'password-reset',
    PASSWORD_CHANGE = 'password-change',
}

const PasswordRedirect = () => {
    const location = useLocation();

    return (
        <Navigate
            to={`/${AuthRoutes.AUTH}/${AuthRoutes.PASSWORD_CHANGE}${location.search}`}
            replace
        />
    );
};

export const AuthRouterConfig: RouteObject[] = [
    {
        path: 'passwordReset',
        element: <PasswordRedirect />,
    },
    {
        path: AuthRoutes.AUTH,
        element: <Auth />,
        children: [
            {
                path: AuthRoutes.LOGIN,
                index: true,
                element: (
                    <RedirectChecker>
                        <Login />
                    </RedirectChecker>
                ),
            },
            {
                path: AuthRoutes.PASSWORD_RESET,
                element: (
                    <RedirectChecker>
                        <PasswordReset />
                    </RedirectChecker>
                ),
            },
            {
                path: AuthRoutes.PASSWORD_CHANGE,
                element: <PasswordChange />,
            },
        ],
    },
];
