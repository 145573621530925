import { RouterProvider } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Toaster } from './components/ui/toast/toaster.tsx';
import AuthInitializer from '@/pages/auth/AuthInitializer';
import { persistor, store } from './redux/store';
import { useMount } from '@/lib/hooks/mount/use-mount.hook.tsx';
import { languageService } from '@/services/language/language.service.ts';
import i18n from '@/i18n.ts';
import dayjs from 'dayjs';

import 'leaflet/dist/leaflet.css';
import { routerConfig } from '@/route/router.config.tsx';
import React, { useEffect } from 'react';
import { LoadingScreen } from './components/ui/loading-screen/loading-screen';
import { ThemeProvider } from '@/components/providers/theme-provider.tsx';
import { useAppVersion } from '@/lib/hooks/app-version/app-version.hook.tsx';
import { toast } from '@/components/ui/toast/use-toast.ts';
import { Button } from '@/components/ui/button/button.tsx';
import { AppIcon, appIcons } from '@/components/ui/app-icon/app-icon.tsx';
import { clsx } from '@/lib/utils.ts';
import { useTranslation } from 'react-i18next';
import { sentryCreateBrowserRouter } from '@/instrument.ts';

const router = sentryCreateBrowserRouter(routerConfig);

function App() {
    const [t] = useTranslation();
    // change app language to the navigator language
    useMount(() => {
        const locale = languageService.setLanguage(navigator.language);
        void i18n.changeLanguage(locale);
        dayjs.locale(locale);
    });

    const { newVersionAvailable } = useAppVersion(10000);

    useEffect(() => {
        if (newVersionAvailable)
            toast({
                title: t('general.appUpdate'),
                description: t('general.appUpdate.reload'),
                action: (
                    <Button
                        onClick={() => window.location.reload()}
                        className={clsx('h-8', 'w-10')}>
                        <AppIcon icon={appIcons.refresh} size={18} />
                    </Button>
                ),
                duration: Infinity,
            });
    }, [newVersionAvailable, t]);

    return (
        <ReduxProvider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <ThemeProvider defaultTheme="light" storageKey={'ui-theme'}>
                    <AuthInitializer />

                    <React.Suspense fallback={<LoadingScreen />}>
                        <RouterProvider router={router} />
                    </React.Suspense>

                    <Toaster />
                </ThemeProvider>
            </PersistGate>
        </ReduxProvider>
    );
}

export default App;
