import { Button } from '@/components/ui/button/button.tsx';
import { useAppDispatch } from '@/lib/hooks';
import { api } from '@/redux/baseConfig';
import { userLogout } from '@/redux/slices/auth';

const NotRolePage = () => {
    const dispatch = useAppDispatch();

    return (
        <div className="flex h-screen w-full items-center justify-center">
            <div className="text-center">
                <div className="mb-4">
                    You dont have permission. Please contact your responsible.
                </div>
                <Button
                    onClick={() => {
                        dispatch(userLogout());
                        dispatch(api.util.resetApiState());
                    }}>
                    Back to login
                </Button>
            </div>
        </div>
    );
};

export default NotRolePage;
